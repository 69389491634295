































import Vue from "vue";
import { Prop } from "vue/types/options";
import eventsRegister from "@/services/events-register";
import { getNameFromClipboard } from "../../utils/utils";
import EasyInput from "@/components/common/EasyInput.vue";

export default Vue.extend({
  name: "AddBuyoutLinks",
  props: {
    list: Array as Prop<any>,
  },
  components: {
    EasyInput,
  },
  computed: {
    isAllFilled(): boolean {
      return this.list.every((item: any) => item.value);
    },
  },
  methods: {
    addLink() {
      if (!this.isAllFilled) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: "Нельзя добавить новое поле, заполните предыдущее",
        });
      }

      const newList: any = [
        ...this.list,
        {
          value: null,
        },
      ];
      this.$emit("update:list", newList);
    },
    async onPaste(idx: number) {
      if (!this.list[idx].value) {
        await getNameFromClipboard((value) => {
          const newList = this.list.map((item: any, i: number) =>
            i === idx ? { value } : item
          );
          this.$emit("update:list", newList);
        });
      }
    },
    onRemove(idx: number) {
      this.$emit(
        "update:list",
        this.list.filter((item: any, i: number) => i !== idx)
      );
    },
  },
});
