

































































































































































































































import Vue from "vue";
import { TranslateResult } from "vue-i18n";

import { leftArrowSVG } from "@/services/svg";
import AddBuyoutInfo from "./AddBuyoutInfo.vue";
import AddBuyoutSuccess from "./AddBuyoutSuccess.vue";
import AddBuyoutLinks from "./AddBuyoutLinks.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import EasyCounter from "@/components/common/EasyCounter.vue";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import TransitionExpand from "@/components/TransitionExpand.vue";

import { createRequest } from "@/services/api-connector";
import eventsRegister from "@/services/events-register";
import {
  getCountryName,
  getOrderRequestData,
  Order,
  isURL,
  getNameFromClipboard,
  logEventFA,
  logEventFB,
} from "../../utils/utils";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: {
    AddBuyoutInfo,
    AddBuyoutSuccess,
    AddBuyoutLinks,
    EasyInput,
    EasyCounter,
    TransitionExpand,
    EasyBottomSheet,
  },
  data() {
    return {
      leftArrowSVG,
      isCreated: false,
      isLoading: false,
      isOpened: false,
      infoIsActive: false,
      isAdditionalOpened: false,
      isBottomSheetOpened: false,
      isSubmitted: false,
      currentStep: 1,
      order: {
        name: "",
        source: null,
        linkList: [],
        photo: null,
        count: 1,
        country: null,
        size: null,
        color: null,
        model: null,
        // type: 11690 По названию товара, 11691 По фотографии, 11599 - URL на магазин, 11600 - Invoice, 11601 - Payment Requisites
      } as Order,
      uploadedFile: null as any,
      maxFileSize: 52428800,
    };
  },
  mounted() {
    // this.$watch(
    //   "appstate.filledProduct",
    //   (newVal: any) => {
    //     if (newVal.source) {
    //       this.order.source = newVal.source;
    //       this.onNext();
    //     }
    //   },
    //   { deep: true, immediate: true }
    // );
  },
  computed: {
    ...mapGetters("user", ["userId", "user"]),
    isFromLink(): boolean {
      const { source } = this.order;
      return isURL(source);
    },
    isFromPhoto(): boolean {
      return !!this.order.photo;
    },
  },
  methods: {
    getRandomName(): string {
      const namesList = `${this.$t("buyOutNames")}`.split(",");
      return namesList[Math.floor(Math.random() * namesList.length)].trim();
    },
    onError(message: TranslateResult) {
      this.$root.$emit(eventsRegister.addMessage, {
        type: "error",
        message,
      });
    },
    onBack() {
      if (this.currentStep === 1) {
        this.reset();
      } else {
        this.currentStep = 1;
      }
    },
    imgFileChanged(e: any): any {
      const inpFileEl: any = e.target;
      const file: any = inpFileEl.files[0];

      if (!file) {
        this.onError(this.$t("fileFormatError"));
        return false;
      }

      const { type, size } = file;

      if (!["image/png", "image/jpg", "image/jpeg"].includes(type)) {
        this.onError(this.$t("fileFormatError"));
        return false;
      }
      if (size > this.maxFileSize) {
        this.onError(this.$t("fileMaxSizeError"));
        return false;
      }

      this.uploadedFile = file;
      const reader: any = new FileReader();
      reader.onloadend = () => {
        const imgBase64: any = reader.result;
        if (imgBase64) {
          this.order.photo = imgBase64;
          this.order.source = null;
          this.order.linkList = [];
          this.onNext();
        }
      };
      reader.readAsDataURL(file);
    },

    removePhoto() {
      this.order.photo = null;
      this.uploadedFile = null;
      this.isAdditionalOpened = false;
      this.currentStep = 1;
    },

    removeSource() {
      this.order.source = null;
      this.order.linkList = [];
      this.isAdditionalOpened = false;
      this.currentStep = 1;
    },

    onNext() {
      this.currentStep = 2;
    },

    onBuy(country: number) {
      this.order.country = country;

      if (!this.isFromLink && !this.order.photo) {
        this.order.name = this.order.source;
        this.onSubmit();
      } else {
        this.toggleBottomSheet(true);
        // this.order.name = this.getRandomName();
      }
    },

    toggleBottomSheet(val: boolean) {
      this.isBottomSheetOpened = val;
    },

    onSubmit() {
      this.isSubmitted = true;

      if (!this.order.name) return false;
      this.toggleBottomSheet(false);
      if (this.isFromPhoto) {
        this.createOrderFromPhoto();
      } else {
        this.createOrderFromSource();
      }
    },

    reset() {
      this.$router.push("/");
      this.isCreated = false;
      this.isAdditionalOpened = false;
      this.currentStep = 1;
      this.uploadedFile = null;
      this.order = {
        name: "Мой новый заказ",
        source: null,
        linkList: [],
        photo: null,
        count: 1,
        country: 287,
        size: null,
        color: null,
        model: null,
      };
      this.isSubmitted = false;
    },

    createOrderFromSource() {
      const { linkList = [] } = this.order;
      this.isLoading = true;
      const source = linkList.length
        ? `${this.order.source}, ${linkList
            .map((el: any) => el.value)
            .join(", ")}`
        : this.order.source;

      const data = getOrderRequestData({
        ...this.order,
        source,
        customerId: this.userId,
        isLink: this.isFromLink,
        type: this.isFromLink ? 11599 : 11690,
        userCountry: this.user.pr201,
      });

      createRequest(
        "objEdit",
        data,
        () => {
          this.$root.$emit(eventsRegister.updateParcels);
          this.isCreated = true;
          this.isLoading = false;
          this.sendSuccessEvents();
        },
        () => (this.isLoading = false)
      );
    },

    createOrderFromPhoto() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("-600", this.uploadedFile);

      const data = getOrderRequestData({
        ...this.order,
        customerId: this.userId,
        type: 11691,
        userCountry: this.user.pr201,
      });

      formData.append("ObjList", JSON.stringify(data));
      createRequest(
        "sendFiles",
        formData,
        () => {
          this.$root.$emit(eventsRegister.updateParcels);
          this.isCreated = true;
          this.isLoading = false;
          this.sendSuccessEvents();
        },
        () => (this.isLoading = false)
      );
    },

    sendSuccessEvents() {
      const { count, country } = this.order;
      logEventFA("add_to_wishlist", {
        quantity: count,
        group_id: getCountryName(country),
        customer_id: this.userId,
      });
      logEventFB("EVENT_NAME_ADDED_TO_WISHLIST", { CONTENT_TYPE: "shopping" });
    },

    async onPaste() {
      if (!this.order.source) {
        await getNameFromClipboard((data) => {
          this.order.source = data;
        });
      }
    },

    onCloseName() {
      this.isSubmitted = false;
    },
  },
});
